<template>
  <Container>
    <p class="form-login__container--description">
      Leave us your email address and we'll send a link to reset your password
    </p>
    <v-form
      class="mt-8"
      @submit.prevent="resetPassword">
      <v-text-field
        v-model="initialCaseEmail"
        placeholder="Email"
        single-line
        filled
        dense
        :error-messages="emailErrors"
        :append-icon="!!emailErrors.length? 'mdi-alert-circle-outline' : ''"
        prepend-inner-icon="mdi-email-outline"
        :error="!!emailErrors.length"
        autocomplete="email"
        @input="errorResetPassword = null" />
      <v-row
        class="d-flex justify-center"
        style="margin-top: 24px">
        <v-btn
          type="submit"
          color="lightBlue"
          dark>
          Send
        </v-btn>
      </v-row>
    </v-form>
  </Container>
</template>

<script>
import { Auth } from 'aws-amplify';
import API from '@/services/graphql';
import {
  required, email,
} from 'vuelidate/lib/validators';
import ConverEmailToLowerCase from '@/mixins/ConverEmailToLowerCase';
import ErrorsText from '@/constants/errors';
import {
  mapMutations, mapState,
} from 'vuex';
export default {
  name: 'WhiteLabelForgotPassword',
  components: {
    Container: () => import('@/views/Agent/Container'),
  },
  mixins: [ConverEmailToLowerCase],
  data: () => ({
    showPassword: false,
    errorResetPassword: null,
    pageObjName: 'reset',
    reset: {
      email: null,
      initialCaseEmail: null,
    },
  }),
  validations: {
    reset: {
      email: {
        required, email,
      },
    },
  },
  computed: {
    ...mapState(['domain']),
    emailErrors() {
      const errors = [];
      if (!this.$v.reset.email.$dirty) return errors;
      if (!this.$v.reset.email.email) {
        errors.push('Invalid e-mail address');
      }
      if (!this.$v.reset.email.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.errorResetPassword) {
        errors.push(this.errorResetPassword);
      }
      return errors;
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    async resetPasswordForInvitedUser() {
      const { email } = this.reset;
      try {
        await API.resetPassword({
          email,
        });
        this.$router.push({
          name: 'white-label',
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.spinner(false);
      }
    },
    async resetPassword() {
      this.$v.reset.$touch();
      if (!this.$v.reset.$invalid) {
        this.spinner(true);
        const { email } = this.reset;
        try {
          await Auth.forgotPassword(email, {
            domain: this.domain,
          });
          this.spinner(false);
          this.$router.push({
            name: 'white-label-reset', params: {
              email,
            },
          });
        } catch (err) {
          const { code, message } = err || {
          };
          if (code === 'InvalidParameterException') {
            this.spinner(false);
            this.$router.push({
              name: 'confirm-sign-up', params: {
                email, signUpBefore: true,
              },
            });
          } else if (code === 'NotAuthorizedException') {
            this.resetPasswordForInvitedUser();
          } else {
            this.spinner(false);
            this.errorResetPassword = message;
          }
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .main-app .v-btn {
    width: max-content;
    padding: 0px 24px !important;
    box-shadow: none;
    // min-width: 124px !important;
    height: 40px !important;

    & ::v-deep.v-btn__content {
      font-size: 16px !important;
      line-height: 135.69%;
      letter-spacing: 0.02em;
      color: #FFFFFF;
    }
  }
}
</style>